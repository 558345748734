import React, { createContext, useContext } from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import tw, { styled } from 'twin.macro';

// MARK:- ModalContext
interface ContextState {
  image: IGatsbyImageData | undefined;
  setImage: React.Dispatch<React.SetStateAction<IGatsbyImageData | undefined>>;
}

export const ModalContext = createContext<ContextState>({
  image: undefined,
  setImage: () => {},
});

// MARK:- Styling
const ModalViewContainer = tw.div`
  fixed z-30 w-screen h-screen bg-transparent flex justify-center
`;

const ModalViewBackground = styled.div`
  width: 100%;
  height: 90%;
  box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(50px);

  ${tw`rounded-md`};
  /* ${tw`ml-2 mr-2 sm:ml-3 sm:mr-3 md:ml-8 md:mr-8 lg:ml-16 lg:mr-16`}; */

  ${tw`bg-white bg-opacity-100 flex justify-center pt-10 pb-10`};
`;

const CloseButton = styled.button`
  ${tw`text-gray-600 text-xl hover:text-black absolute right-0 top-0 pt-2 pr-4 pl-4 pb-2`}
`;

// MARK:- Component
function ModalView() {
  const { image, setImage } = useContext(ModalContext);

  const closeModal = () => setImage(undefined);

  return (
    <div>
      {image && (
        <ModalViewContainer>
          <ModalViewBackground>
            <GatsbyImage alt="image" image={image} objectFit="contain" />
            <CloseButton type="button" onClick={closeModal}>
              X
            </CloseButton>
          </ModalViewBackground>
        </ModalViewContainer>
      )}
    </div>
  );
}

export default ModalView;
