import * as React from 'react';
import tw, { styled } from 'twin.macro';
import NavigationBarLinks from './NavigationBarLinks';

import { Collapsible } from '../types/Collapsible';

const Navigation = styled.nav`
  display: flex;
  justify-content: center;
  z-index: 2;
  align-self: center;
  margin: 0;

  @media (max-width: 768px) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    padding-right: 0.5rem;
  }
`;

const Triangle = styled.svg<Collapsible>`
  ${tw`fill-current transition-all duration-300 ease-linear self-center`};
  transform: rotate(${({ isCollapsed }) => (isCollapsed ? '0' : '90')}deg);
`;

const Toggle = tw.button`
  md:hidden cursor-pointer
`;

function Icon({ isCollapsed = true }: Collapsible) {
  return (
    <Triangle
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      isCollapsed={isCollapsed}
    >
      <path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z" />
    </Triangle>
  );
}

function NavigationBar() {
  const [isCollapsed, setNavbarCollapsed] = React.useState(true);

  const toggleCollapse = () => setNavbarCollapsed(!isCollapsed);

  return (
    <Navigation>
      {/* <Triangle /> */}
      <Toggle
        // navbarOpen={navbarOpen}
        onClick={toggleCollapse}
      >
        <Icon isCollapsed={isCollapsed} />
      </Toggle>
      <NavigationBarLinks isCollapsed={isCollapsed} />
    </Navigation>
  );
}

export default NavigationBar;
