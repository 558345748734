/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { motion } from 'framer-motion';
import { Collapsible } from '../types/Collapsible';

interface DropdownProps<AnimationProps> extends Collapsible {
  animation?: {
    hide: AnimationProps;
    show: AnimationProps;
  };
  className?: string;
}

export default function Dropdown<AnimationProps>({
  isCollapsed,
  animation,
  children,
  className,
}: React.PropsWithChildren<DropdownProps<AnimationProps>>) {
  return (
    <motion.div
      variants={animation}
      initial="hide"
      animate={isCollapsed ? 'hide' : 'show'}
      className={className}
    >
      {children}
    </motion.div>
  );
}
