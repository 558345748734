/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react';
import { motion } from 'framer-motion';
import { TransitionStateMock } from '../mocks/transition-link-mock';

interface FadeOutAlongYWrapperProps {
  children: React.ReactNode;
}

export default function FadeOutAlongYWrapper({
  children,
}: FadeOutAlongYWrapperProps) {
  return (
    <TransitionStateMock>
      {({ transitionStatus, entry, exit, mount }) =>
        mount && (
          <motion.div
            initial={entry.state}
            animate={
              transitionStatus === 'exiting'
                ? exit.state
                : { opacity: 1, x: 0, y: 0 }
            }
            transition={{
              duration: 1.5,
              type: 'tween',
              ease: 'easeInOut',
            }}
          >
            {children}
          </motion.div>
        )
      }
    </TransitionStateMock>
  );
}
