/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import tw, { styled } from 'twin.macro';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { motion } from 'framer-motion';

import TransitionLinkMock from '../mocks/transition-link-mock';

import { Collapsible } from '../types/Collapsible';
import Dropdown from './Dropdown';

interface NavigationLinkProps<AnimationProps> {
  to: string;
  children: React.ReactNode;
  variants?: {
    hide: AnimationProps;
    show: AnimationProps;
  };
  className?: string;
}

function NavigationLink<AnimationProps>({
  to,
  children,
  variants,
  className,
}: NavigationLinkProps<AnimationProps>) {
  return (
    <motion.div variants={variants} className={className}>
      <TransitionLinkMock
        to={to}
        exit={{ length: 0.4, state: { y: '-100%', opacity: 0 } }}
        entry={{ delay: 0.4, state: { y: '-100%', opacity: 0 } }}
      >
        {children}
      </TransitionLinkMock>
    </motion.div>
  );
}

const StyledNavigationLink = styled(NavigationLink)`
  ${tw`pl-2 md:pl-4 pt-1 pb-0 pr-1 md:opacity-100!`};
`;

interface DropdownProps<AnimationProps> {
  animation: {
    hide: AnimationProps;
    show: AnimationProps;
  };
  className: string;
}

const NavigationBarLinkContainer = styled(Dropdown)`
  /* text */
  ${tw`text-right rounded-md md:opacity-100!`}

  /* mobile layout */
  ${tw`absolute top-4 right-1/2 z-10 flex-col`}
  ${(props) => (props.isCollapsed ? tw`hidden` : tw`flex`)}
  
  /* tablet/desktop layout */
  ${tw`md:relative md:top-0 md:right-0 md:flex-row md:justify-center md:visible! md:flex!`};

  @media (max-width: 768px) {
    backdrop-filter: blur(10px);
  }
`;

interface NavProps {
  opacity?: number;
  transition?: {
    duration?: number;
    staggerChildren?: number;
    staggerDuration?: number;
  };
}

function NavbarLinks({ isCollapsed }: Collapsible) {
  const links = ['portfolio', 'exhibitions', 'about'];
  const item = {
    hide: { opacity: 0 },
    show: { opacity: 1 },
  };

  const variants = {
    hide: {
      opacity: 0,
      transition: {
        duration: 1.5,
        staggerChildren: 0.5,
        staggerDirection: -1,
      },
    },
    show: {
      opacity: 1,
      transition: {
        duration: 1.5,
        staggerChildren: 0.5,
      },
    },
  };

  const { t } = useTranslation();

  return (
    <NavigationBarLinkContainer isCollapsed={isCollapsed} animation={variants}>
      {links.map((link) => (
        <StyledNavigationLink variants={item} to={`/${link}`} key={link}>
          {t(link)}
        </StyledNavigationLink>
      ))}
    </NavigationBarLinkContainer>
  );
}

export default NavbarLinks;
