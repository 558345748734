import * as React from 'react';
import { Link } from 'gatsby';
import tw, { styled } from 'twin.macro';
import { useTranslation } from 'gatsby-plugin-react-i18next';

interface FooterProps {
  landing: boolean;
}

const FooterContainer = styled.footer<FooterProps>`
  /* Postion */
  ${tw`flex flex-col md:flex-row w-full justify-center text-center md:space-x-4`};

  font-size: 0.5rem /* 12px */;
  line-height: 0.75rem /* 16px */;

  /* text size */
  ${tw`lg:text-sm`};

  /* color */
  ${(props) =>
    props.landing
      ? tw`text-white absolute left-0 bottom-0`
      : tw`text-gray-600`};

  /* padding */
  ${tw`p-1`}
`;

const FooterLinkContainer = styled.div`
  ${tw`flex space-x-4 justify-center`}

  a {
    ${tw`hover:text-black`}
  }
`;

function Footer({ landing = false }: FooterProps) {
  const { t } = useTranslation();
  return (
    <FooterContainer landing={landing}>
      <FooterLinkContainer>
        <a href="/legal-notice#contact">{t('contact')}</a>
        <Link to="/legal-notice">{t('legal-notice')}</Link>
        <Link to="/privacy-policy">{t('privacy-policy')}</Link>
      </FooterLinkContainer>
      <p>copyright © 2009 - 2021 klaus vogelgesang</p>
    </FooterContainer>
  );
}

export default Footer;
