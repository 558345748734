import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import tw, { styled } from 'twin.macro';
import Header from './Header';
import Footer from './Footer';
import PageTransition from './PageTransition';
import SEO from './SEO';
import { HorizontalPadding, VerticalPadding } from './Padding';
import ModalView, { ModalContext } from './ModalView';

// MARK: Types
interface LayoutProps {
  children?: React.ReactNode;
  landing?: boolean;
}

interface SiteTitleQueryProps {
  site: {
    siteMetadata: {
      title: string;
    };
  };
}

const PseudoTopSpacer = tw(VerticalPadding)`
  invisible pt-14
`;

const Container = styled.div`
  [id]::before {
    content: '';
    display: block;
    height: 75px;
    margin-top: -75px;
    visibility: hidden;
  }
`;

// MARK: Component
export default function Layout({ children, landing = false }: LayoutProps) {
  const data = useStaticQuery<SiteTitleQueryProps>(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const [image, setImage] = React.useState<IGatsbyImageData>();

  return (
    <Container>
      <SEO />
      <Header title={data.site.siteMetadata.title} landing={landing} />
      <div>
        <ModalContext.Provider value={{ image, setImage }}>
          <PseudoTopSpacer />
          <ModalView />
          <HorizontalPadding>
            <PageTransition>{children}</PageTransition>
            <Footer landing={landing} />
          </HorizontalPadding>
        </ModalContext.Provider>
      </div>
    </Container>
  );
}
