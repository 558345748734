import * as React from 'react';
// import { Link } from 'gatsby';
import tw, { styled } from 'twin.macro';
import FadeOutAlongYWrapper from './FadeOutAlongYWrapper';
import HeaderLink from './HeaderLink';
import { HorizontalPadding, VerticalPadding } from './Padding';
import NavigationBar from './NavigationBar';
import LanguageSwitcher from './LanguageSwitcher';

interface HeaderProps {
  landing: boolean;
}

const HeaderContainer = styled.header<HeaderProps>`
  ${tw`w-full font-sans justify-between flex bg-transparent`};

  ${(props) => (props.landing ? tw`text-white` : tw`text-gray-600`)};

  a {
    ${tw`whitespace-nowrap text-lg sm:text-xl hover:text-black`};
  }
`;

const HeaderBackground = styled(HorizontalPadding)<HeaderProps>`
  background: ${({ landing }) =>
    landing ? 'rgba(255, 255, 255, 0)' : 'rgba(255, 255, 255, 0.9)'};
  backdrop-filter: ${({ landing }) => (landing ? 'blur(0px)' : 'blur(50px)')};

  ${tw`fixed w-full font-sans justify-between items-center z-50`};
`;

interface HeaderComponentProps extends HeaderProps {
  title: string;
}

export default function Header({ title, landing }: HeaderComponentProps) {
  return (
    <HeaderBackground landing={landing}>
      <VerticalPadding>
        <FadeOutAlongYWrapper>
          <HeaderContainer landing={landing}>
            <HeaderLink to="/">{title}</HeaderLink>
            <div tw="flex sm:flex-row mt-auto">
              <LanguageSwitcher />
              <div tw="mt-auto">
                <NavigationBar />
              </div>
            </div>
          </HeaderContainer>
        </FadeOutAlongYWrapper>
      </VerticalPadding>
    </HeaderBackground>
  );
}
