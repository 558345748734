import * as React from 'react';
import tw, { styled } from 'twin.macro';
import TransitionLinkMock from '../mocks/transition-link-mock';

export interface HeaderLinkProps {
  to: string;
  children: React.ReactNode;
}

const StyledTransitionLink = styled(TransitionLinkMock)`
  ${tw`hover:text-black`};
`;

export default function HeaderLink({ to, children }: HeaderLinkProps) {
  return (
    <StyledTransitionLink
      to={to}
      exit={{ length: 0.4, state: { y: '-100%', opacity: 0 } }}
      entry={{ delay: 0.4, state: { y: '-100%', opacity: 0 } }}
    >
      {children}
    </StyledTransitionLink>
  );
}
