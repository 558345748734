import * as React from 'react';
// import { motion } from 'framer-motion';
// import { TransitionStateMock } from '../mocks/transition-link-mock';

interface PageTransitionProps {
  children: React.ReactNode;
}

// interface TransitionStateProps {
//   // eslint-disable-next-line react/no-unused-prop-types
//   transitionStatus: string;
// }

function PageTransition({ children }: PageTransitionProps) {
  // const variants = {
  //   hidden: {
  //     opacity: 0,
  //   },
  //   visible: {
  //     opacity: 1,
  //   },
  // };
  return <div>{children}</div>;
  // <TransitionStateMock>
  //   {({ transitionStatus }: TransitionStateProps) => (
  //     <motion.div
  //       initial={transitionStatus === 'exiting' ? 'visible' : 'hidden'}
  //       variants={variants}
  //       animate={transitionStatus === 'exiting' ? 'hidden' : 'visible'}
  //       transition={{
  //         duration: 1.5,
  //         type: 'tween',
  //         ease: 'easeInOut',
  //       }}
  //     >
  //       {children}
  //     </motion.div>
  //   )}
  // </TransitionStateMock>
}

export default PageTransition;
