/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link, GatsbyLinkProps } from 'gatsby';
// import TransitionLink, {
//   TransitionState,
//   TransitionStatuses,
// } from 'gatsby-plugin-transition-link';

// const MOCK = true;

interface TransitionStateMockProps {
  children: ({
    mount,
    transitionStatus,
  }: {
    mount: boolean;
    transitionStatus: string;
    exit: {
      length: number;
      state: {
        y: string;
        opacity: number;
      };
    };
    entry: {
      delay: number;
      state: {
        y: string;
        opacity: number;
      };
    };
  }) => React.ReactNode;
}

export function TransitionStateMock({ children }: TransitionStateMockProps) {
  // if (MOCK) {
  const props = {
    transitionStatus: 'entering',
    mount: true,
    exit: { length: 0.4, state: { y: '-100%', opacity: 0 } },
    entry: { delay: 0.4, state: { y: '-100%', opacity: 0 } },
  };
  return <div>{children(props)}</div>;
  // }

  // return (
  //   <TransitionState>
  //     {(props) => {
  //       console.log(props);
  //       children(props);
  //     }}
  //   </TransitionState>
  // );
}

interface EntryExit<State = object> {
  state?: State;
  appearAfter?: number;
  length?: number;
  zIndex?: number;
  delay?: number;
  activeClass?: string;
  className?: string;
}

interface TransitionLinkProps {
  to: GatsbyLinkProps<any>['to'];
  exit?: EntryExit;
  entry?: EntryExit;
  children: React.ReactNode;
}

function TransitionLinkMock({
  to,
  exit,
  entry,
  children,
}: TransitionLinkProps) {
  // if (MOCK) {
  return <Link to={to}>{children}</Link>;
  // }

  // return (
  //   <TransitionLink to={to} exit={exit} entry={entry}>
  //     {children}
  //   </TransitionLink>
  // );
}

export default TransitionLinkMock;
